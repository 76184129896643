// Generated by Framer (28b2919)

import { addFonts, cx, CycleVariantState, getFonts, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Gif as GIF } from "https://framerusercontent.com/modules/LC4heOHJXh5Q0v49H98F/s6UELBTMwAduQOGcYAdN/Gif.js";
const GIFFonts = getFonts(GIF);

const cycleOrder = ["afLY97oUe"];

const variantClassNames = {afLY97oUe: "framer-v-rkwsj1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, syXMLoJ7aE8MuRUNcZ, pri4k5otXE8MuRUNcZ, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "afLY97oUe", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-lHAsK", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-rkwsj1", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"afLY97oUe"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-3b0bf21b-a629-4594-a176-dfa86a291700, rgb(0, 0, 0)) /* {\"name\":\"day\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 17, borderBottomRightRadius: 17, borderTopLeftRadius: 17, borderTopRightRadius: 17, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7U3BhY2UgTW9uby1yZWd1bGFy", "--framer-font-family": "\"Space Mono\", monospace", "--framer-font-size": "calc(var(--variable-reference-syXMLoJ7a-E8MuRUNcZ) * 1px)"}}>Software</motion.p></React.Fragment>} className={"framer-77zfxf"} fonts={["GF;Space Mono-regular"]} layoutDependency={layoutDependency} layoutId={"xRLJ21ldd"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px", "--variable-reference-syXMLoJ7a-E8MuRUNcZ": syXMLoJ7aE8MuRUNcZ}} text={pri4k5otXE8MuRUNcZ} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-qxng5d-container"} layoutDependency={layoutDependency} layoutId={"TLIr85H8D-container"} style={{opacity: 0}}><GIF bottomLeft={6} bottomRight={6} height={"100%"} id={"TLIr85H8D"} isMixed={false} layoutId={"TLIr85H8D"} lockImage radius={6} search={"wave"} style={{height: "100%", width: "100%"}} topLeft={6} topRight={6} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-lHAsK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lHAsK .framer-1qgbehs { display: block; }", ".framer-lHAsK .framer-rkwsj1 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 100px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 529px; will-change: var(--framer-will-change-override, transform); }", ".framer-lHAsK .framer-77zfxf { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-lHAsK .framer-qxng5d-container { aspect-ratio: 1.1764705882352942 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 0px); position: relative; width: 0px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-lHAsK .framer-rkwsj1 { gap: 0px; } .framer-lHAsK .framer-rkwsj1 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-lHAsK .framer-rkwsj1 > :first-child { margin-left: 0px; } .framer-lHAsK .framer-rkwsj1 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 100
 * @framerIntrinsicWidth 529
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const Framergo3j4sHf1: React.ComponentType<Props> = withCSS(Component, css, "framer-lHAsK") as typeof Component;
export default Framergo3j4sHf1;

Framergo3j4sHf1.displayName = "Variant 4 - light";

Framergo3j4sHf1.defaultProps = {height: 100, width: 529};

addFonts(Framergo3j4sHf1, [{family: "Space Mono", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/go3j4sHf1:default", url: "https://fonts.gstatic.com/s/spacemono/v13/i7dPIFZifjKcF5UAWdDRUEZ2RFq7AwU.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/spacemono/v13/i7dPIFZifjKcF5UAWdDRUEZ2RFq7AwU.ttf", weight: "400"}, ...GIFFonts])